<template>
  <div class="template">
    <div v-show="spinning" class="loading-box">
      <a-spin
        class="loading-child"
        tip="Loading..."
        :spinning="spinning"
        :delay="500"
      >
      </a-spin>
    </div>
    <div class="bg-color" v-if="showBg"></div>
    <div class="complie-box">
      <div class="complie-box-model">
        <Complie-AddModel-Remark
          :class="modelAccout ? 'modelAccoutShow' : 'modelAccoutHide'"
        />
        <!-- 商品购物车 -->
        <div class="Shopping-Father-Box" @click.stop="HideShoppingModel(false)">
          <transition name="slide-fade">
            <ShoppingModel
              v-if="shoppingModel"
              :showMsg="showMbean"
              @HideShoppingModel="HideShoppingModel"
            />
          </transition>
        </div>
        <!-- 个性化模态框 -->
        <transition name="slide-fade" mode="out-in">
          <LoginComponents v-if="showLoginModelAccout" />
        </transition>
        <transition name="slide-fade" mode="out-in">
          <LookUserMessage v-if="userDataModel" />
        </transition>
        <!-- <transition name="slide-fade" mode="out-in">
          <ComplieLeftMenuShareTIps v-if="hideDgModelAccout" />
        </transition> -->
      </div>
      <div class="comlie-content">
        <Complie-Header :crispsUserData="crispsMindUserData" />
      </div>
      <!-- <div class="comlie-content-menu">
        顶部And左侧个性化组价
        <Complie-Left-Menu />
        <Complie-Header-Menu />
      </div> -->
      <div class="comlie-center-content">
        <a-layout id="components-layout-demo-side">
          <a-layout>
            <a-layout-content>
              <div class="comlie-left-content">
                <!-- 音频 -->
                <div class="complie-left-audio" v-if="httpAudio != ''">
                  <ComplieAudioLeft :httpAudioData="httpAudio" />
                </div>
                <!-- 展示风暴用户信息操作 -->
                <div class="crisps-detial-header">
                  <div class="crisps-user-message" @click="getUserProfile()">
                    <div
                      class="userIsMember"
                      v-if="this.crispsMindData.userIsMember"
                    >
                      <img :src="crispsMindData.userAvatar" alt="" />
                    </div>
                    <div class="user-header" v-else>
                      <img
                        :src="crispsMindData.userAvatar"
                        onerror="this.src='/static/img/userData/avatar_yellow_man.png'"
                        alt=""
                      />
                    </div>
                    <div class="user-name">
                      {{ crispsMindData.username }}
                    </div>
                  </div>
                  <div class="crisps-detials-handle">
                    <!-- 点赞 -->
                    <div
                      class="crisps-like-box"
                      @click="postCrispsVoteCrisps(crispsMindData.crispsId)"
                    >
                      <div v-if="crispsMindData.voted" class="crisps-like">
                        <div class="iconfont icon-aixin-before">&#58972;</div>
                        <span class="crisps-like-text">{{
                          crispsMindData.voteCount
                        }}</span>
                      </div>
                      <div v-else class="crisps-like">
                        <div class="iconfont icon-aixin">&#58881;</div>
                        <span
                          v-if="crispsMindData.voteCount > 0"
                          class="crisps-like-text"
                          >{{ crispsMindData.voteCount }}</span
                        >
                      </div>
                    </div>
                    <!-- 评论 -->
                    <div class="crisps-like-box" @click="crispsComment">
                      <div class="crisps-like">
                        <div
                          class="iconfont icon-weibiaoti"
                          style="font-size: 24px"
                        >
                          &#58896;
                        </div>
                        <span
                          v-if="commentContent.length > 0"
                          class="crisps-like-comment"
                          >{{ commentContent.length }}</span
                        >
                      </div>
                    </div>
                    <!-- 收藏 -->
                    <div
                      class="crisps-like-box"
                      @click="postCrispsCollect(crispsMindData.crispsId)"
                    >
                      <!-- <div class="crisps-like" v-if="crispsMindData.collected">
                        <div
                          class="iconfont icon-shoucang"
                          style="font-size: 22px"
                        >
                          &#58913;
                        </div>
                      </div>
                      <div class="crisps-like" v-else>
                        <div
                          class="iconfont icon-shoucang-befor"
                          style="font-size: 22px"
                        >
                          &#58950;
                        </div>
                      </div> -->
                      <span class="crisps-like">
                        <img
                          v-if="!crispsMindData.collected && isDarkColor"
                          src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/collectNot.svg"
                          alt=""
                        />
                        <img
                          v-else-if="!crispsMindData.collected && !isDarkColor"
                          src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/collectWhite.svg"
                          alt=""
                        />
                        <img
                          v-else
                          src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/collect.svg"
                          alt=""
                        />
                      </span>
                    </div>
                    <!-- 分享 -->
                    <div class="crisps-like-box">
                      <div class="crisps-like">
                        <div
                          class="iconfont share-icon"
                          @click="srispsShare"
                          style="font-size: 22px"
                        >
                          &#58888;
                        </div>
                      </div>
                    </div>
                    <!-- 大纲or导图 -->
                    <div class="mindmap-or-outline" v-if="mobileShowOutlineMap">
                      <span
                        v-if="titelAccout"
                        @click="setAccoutTitel(0)"
                        class="mindicon"
                      >
                        &#58884;
                      </span>
                      <span v-else @click="setAccoutTitel(1)" class="Outline">
                        &#58882;
                      </span>
                    </div>
                    <!-- 导图播放 -->
                    <div
                      class="mindmap-play"
                      v-if="getMindmapPattern()"
                      @click="mindmapPlay()"
                    >
                      <span class="mp-play">
                        <img
                          v-if="isDarkColor"
                          src="../../assets/img/mindNewEditMenuIocn/playMind.svg"
                          alt=""
                        />
                        <img
                          v-else
                          src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/playMindWhite.svg"
                          alt=""
                        />
                      </span>
                    </div>
                    <!-- 保存 -->
                    <button
                      class="payment-use"
                      v-if="crispsMindData.isUsable"
                      @click="paymentUse"
                    >
                      <!-- <img src="../../assets/img/storm/icon/saveCrisps.svg" alt="" width="19px">  -->
                      <template v-if="crispsMindData.mbeansRequired != 0">
                        <span>{{ crispsMindData.mbeansRequired }}</span>
                        <img
                          class="m-img"
                          src="../../assets/img/storm/mbeans.svg"
                          alt=""
                          width="12px"
                        />
                      </template>
                      <span>{{ isSave }}</span>
                      <!-- <span v-if="!isMember || isMoble || crispsMindData.mbeansRequired != 0">{{ getString(strings.Mind_Group_Save) }}</span>
                      <span v-else>{{ getString(strings.Members_Save_Free) }}</span> -->
                    </button>
                  </div>
                </div>
                <transition name="slide-fade" mode="out-in">
                  <ComplieLeftContent
                    v-show="!hideDgModelAccout"
                    :initCrispsData="crispsMindData"
                  />
                </transition>
                <transition name="slide-fade" mode="out-in">
                  <ComplieLeftOutline v-show="hideDgModelAccout" />
                </transition>
              </div>
            </a-layout-content>
          </a-layout>
          <transition name="slide-fade" mode="out-in">
            <div class="comlie-right-menu-box" v-if="crispsCommentComponent">
              <div class="storm-comment-box">
                <div class="storm-comment-text">
                  <span>{{ getString(strings.Storm_Share_Comment) }}</span>
                </div>
                <!-- 评论数据 -->
                <div
                  class="storm-user-comment"
                  v-if="commentContent.length > 0"
                >
                  <div
                    class="storm-user-comment-message"
                    v-for="(item, index) in commentContent"
                    :key="index"
                  >
                    <!-- 用户评论 -->
                    <div class="storm-user-comment-box">
                      <div
                        class="storm-user-header"
                        @click="commentToUserData(item)"
                      >
                        <div
                          v-if="item.isMember"
                          class="storm-user-img-ismember"
                        >
                          <img :src="item.userAvatar" alt="" />
                        </div>
                        <div v-else class="storm-user-img">
                          <img
                            :src="item.userAvatar"
                            onerror="this.src='/static/img/userData/avatar_yellow_man.png'"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="storm-comment-content">
                        <div class="comment-content-header">
                          <div class="comment-user-name">
                            <span>{{ item.username }}</span>
                            <span
                              v-if="item.userId == crispsMindData.userId"
                              class="comment-author-logo"
                              >{{ getString(strings.Storm_Share_Author) }}</span
                            >
                          </div>
                          <div class="comment-cotnent-detials">
                            <a-popover
                              v-model="item.visible"
                              placement="bottomLeft"
                              trigger="click"
                            >
                              <template slot="content">
                                <div
                                  class="chips-delete-content"
                                  @click="
                                    reportCrisps(index, item.id, {
                                      type: 'comment',
                                      index: index,
                                    })
                                  "
                                >
                                  <img
                                    src="../../../static/img/userData/report-crisps-logo.png"
                                    alt=""
                                  />
                                  <span>{{
                                    getString(strings.Mind_Storm_Report)
                                  }}</span>
                                </div>
                                <div
                                  v-if="
                                    item.userId == userDataId.userId ||
                                    crispsMindData.userId == userDataId.userId
                                  "
                                  class="chips-delete-content"
                                  @click="
                                    reportCrisps(index, item.id, {
                                      type: 'commentDelete',
                                      index: index,
                                    })
                                  "
                                >
                                  <img
                                    src="../../../static/img/userData/report-crisps-logo.png"
                                    alt=""
                                  />
                                  <span>{{
                                    getString(
                                      strings.Mind_Mindmap_Deleted_Forever
                                    )
                                  }}</span>
                                </div>
                              </template>
                              <a-button>
                                <img
                                  src="../../../static/img/userData/vertical_detials.png"
                                  alt=""
                                />
                              </a-button>
                            </a-popover>
                          </div>
                        </div>
                        <div class="comment-content-box">
                          <div class="comment-content-text">
                            <span>{{ item.content }}</span>
                          </div>
                        </div>
                        <div class="comment-content-reply">
                          <div class="comment-time">
                            {{ formatTime(item.createTime) }}
                          </div>
                          <div
                            class="comment-reply"
                            @click="commentReply(index, item)"
                          >
                            {{ getString(strings.Storm_Share_Reply) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 用户回复 -->
                    <div
                      class="storm-comment-reply"
                      v-if="commentContent[index].topComments.length > 0"
                    >
                      <!-- 回复评论 -->
                      <div
                        class="storm-user-comment-message-reply"
                        v-for="(itemReply, replyIndex) in commentContent[index]
                          .topComments"
                        :key="replyIndex"
                      >
                        <div
                          class="storm-user-header"
                          @click="commentToUserData(itemReply)"
                        >
                          <div
                            v-if="itemReply.isMember"
                            class="storm-user-img-ismember"
                          >
                            <img :src="itemReply.userAvatar" alt="" />
                          </div>
                          <div v-else class="storm-user-img">
                            <img
                              :src="itemReply.userAvatar"
                              onerror="this.src='/static/img/userData/avatar_yellow_man.png'"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="storm-comment-content">
                          <div class="comment-content-header">
                            <div class="comment-user-name">
                              <span>{{ itemReply.username }}</span>
                              <span
                                v-if="itemReply.userId == crispsMindData.userId"
                                class="comment-author-logo"
                                >{{
                                  getString(strings.Storm_Share_Author)
                                }}</span
                              >
                            </div>
                            <div class="comment-cotnent-detials">
                              <a-popover
                                v-model="itemReply.visible"
                                placement="bottomLeft"
                                trigger="click"
                              >
                                <template slot="content">
                                  <div
                                    class="chips-delete-content"
                                    @click="
                                      reportCrisps(index, itemReply.id, {
                                        type: 'reply',
                                        index: replyIndex,
                                      })
                                    "
                                  >
                                    <img
                                      src="../../../static/img/userData/report-crisps-logo.png"
                                      alt=""
                                    />
                                    <span>{{
                                      getString(strings.Mind_Storm_Report)
                                    }}</span>
                                  </div>
                                  <div
                                    v-if="
                                      itemReply.userId == userDataId.userId ||
                                      crispsMindData.userId == userDataId.userId
                                    "
                                    class="chips-delete-content"
                                    @click="
                                      reportCrisps(index, itemReply.id, {
                                        type: 'replyDelete',
                                        index: replyIndex,
                                      })
                                    "
                                  >
                                    <img
                                      src="../../../static/img/userData/report-crisps-logo.png"
                                      alt=""
                                    />
                                    <span>{{
                                      getString(
                                        strings.Mind_Mindmap_Deleted_Forever
                                      )
                                    }}</span>
                                  </div>
                                </template>
                                <a-button>
                                  <img
                                    src="../../../static/img/userData/vertical_detials.png"
                                    alt=""
                                  />
                                </a-button>
                              </a-popover>
                            </div>
                          </div>
                          <div class="comment-content-box">
                            <div class="comment-content-text">
                              <span>{{ itemReply.content }}</span>
                            </div>
                          </div>
                          <div class="comment-content-reply">
                            <div class="comment-time">
                              {{ formatTime(itemReply.createTime) }}
                            </div>
                            <!-- <div class="comment-reply">回复</div> -->
                          </div>
                        </div>
                      </div>
                      <!-- 展开更多回复 -->
                      <div
                        class="comment-reply-more"
                        v-if="
                          !commentContent[index].isEnd &&
                          commentContent[index].commentCount >
                            commentContent[index].topComments.length
                        "
                      >
                        <span
                          @click="moreReply(index, commentContent[index].id)"
                          >{{ getString(strings.Mind_Storm_Unfold)
                          }}{{
                            commentContent[index].commentCount -
                            commentContent[index].topComments.length
                          }}{{ getString(strings.Storm_Share_Replies) }}
                        </span>
                        <div class="more-comment-logo"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="storm-user-comment-nodata" v-else>
                  <div class="nodata-box">
                    <img
                      src="../../assets/img/storm/storm_commend_nodata.png"
                      alt=""
                    />
                    <p>
                      {{ getString(strings.Storm_Share_Comment_Is_Null_Tips) }}
                    </p>
                  </div>
                </div>
                <!-- 发布 -->
                <div class="storm-user-form">
                  <a-form :form="commentForm" @submit="commentSubmit">
                    <a-form-item>
                      <a-input
                        v-decorator="[
                          'commentContent',
                          {
                            rules: [
                              {
                                required: true,
                                message: getString(
                                  strings.Storm_Share_Comment_Input_Is_Null_Tips
                                ),
                              },
                            ],
                          },
                        ]"
                        :placeholder="commentName"
                        ref="commentInput"
                        @change="checkLength($event, 96)"
                      />
                    </a-form-item>
                    <a-form-item class="storm-user-form-button">
                      <a-button type="primary" html-type="submit">
                        {{ getString(strings.Storm_Share_Release) }}
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
              </div>
            </div>
          </transition>
        </a-layout>
      </div>
    </div>
    <!-- 举报模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportCrispsVisible"
      :title="getString(strings.Mind_Storm_Report)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
      @ok="reportCrispsHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          v-for="(item, index) in reportCrispsContentList"
          :key="index"
          @click="reportCrispsClick(index)"
        >
          <div class="report-crisps-left">
            {{ item }}
          </div>
          <div class="report-crisps-right">
            <div
              class="report-crisps-defualt-click"
              :class="
                reportCrispsContentListNumber == index
                  ? 'report-defualt-click'
                  : ''
              "
            ></div>
            <img
              v-if="reportCrispsContentListNumber == index"
              src="../../../static/img/userData/crisps-reporet-click.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 删除模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportCrispsDelete"
      :title="getString(strings.Mind_Mindmap_Deleted_Forever)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
      @ok="commentCrispsDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Storm_Share_Delete_Comment_Tips) }}
        </div>
      </div>
    </a-modal>
    <!-- 分享模态框 -->
    <a-modal
      class="report-crisps-model-share"
      :centered="true"
      v-model="reportCrispsShareModel"
      :title="getString(strings.Mind_Edit_Left_Menu_Share_Links)"
      :okText="getString(strings.Mind_Edit_Left_Menu_Copy_Content_And_Link)"
      @ok="commentCrispsShareHandleOk"
    >
      <div class="report-crisps-style-box">
        <!-- 二维码 -->
        <div class="com-mind-qr">
          <vue-qr
            :logoSrc="logoSrc"
            :logoScale="0.2"
            :text="crispsMindData.link"
            :size="135"
          ></vue-qr>
        </div>
        <!-- 标题链接 -->
        <div class="report-crisps-title-box">
          <div class="report-crips-title">
            <div class="title">
              {{ getString(strings.Storm_Share_Main_Title) }}
            </div>
            <div class="content">
              {{ shareCrispsLinkTitle }}
              <!-- <input
                type="text"
                :readonly="true"
                :value="shareCrispsLinkTitle"
              /> -->
            </div>
          </div>

          <div class="report-crips-subtitle">
            <div class="title">
              {{ getString(strings.Storm_Share_Subtitle) }}
            </div>
            <div class="content">
              {{ shareCrispsLinkSubTitle }}
              <!-- <input
                type="text"
                :readonly="true"
                :value="shareCrispsLinkSubTitle"
              /> -->
            </div>
          </div>

          <div class="com-share-open copy-link">
            <div class="title">{{ getString(strings.User_Message_Link) }}</div>
            <input type="text" :readonly="true" v-model="crispsMindData.link" />
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 个性化加载封面 -->
    <!-- <ComplieShowImg /> -->
    <ComplieShowImg />

    <ComplieHelp />
    <SettingUpPopup
      :contactShow="showContactUs"
      @showContactModal="showContactModal"
    />
    <AboutProject
      :showAboutProject="showAboutProject"
      @showAboutModal="showAboutModal"
    />
    <Usermessage v-if="userMessage" @HideUserMessage="HideUserMessage" />
    <!-- 用户中心 -->
    <!-- <LookUserMessage v-if="userDataModel" /> -->
  </div>
</template>
<script>
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, remote } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
import AboutProject from "../../components/aboutProject/aboutProject";
import SettingUpPopup from "../../components/electronMenu/SettingUpPopup";
import Usermessage from "../../components/userMessage/UserMessage";
import LookUserMessage from "../../components/userMessage/LookUserMessage";
import ComplieHelp from "../../components/complieComponents/ComplieHelp/ComplieHelp";

import { mapMutations } from "vuex";

import vueQr from "vue-qr";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";

// import LookUserMessage from "../../components/userMessage/LookUserMessage";
import ComplieHeader from "../../components/complieComponents/ComplieHeader";
import LoginComponents from "../../components/loginComponents/LoginComponents";
import ComplieLeftContent from "../../components/complieComponents/ComplieLeftContent";
import ComplieLeftOutline from "../../components/complieComponents/ComplieLeftOutline";
import ComplieShowImg from "../../components/complieComponents/ComplieShowImg/ComplieShowImg";
import ComplieAudioLeft from "../../components/complieComponents/ComplieHeader/ComplieAudioLeft";
import ComplieMindmapPlayModal from "../../components/complieComponents/ComplieHeader/ComplieMindmapPlayModal";
import ComplieLeftMenuShareTIps from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShareTIps";
import ComplieAddModelRemark from "../../components/complieComponents/ComplieHeader/ComplieAddModel/ComplieAddModelRemark";

import MindOperateUIControllerView from "../../components/complieComponents/tools/MindOperateUIControllerView";

import {
  postCrispsCollect,
  postCrispsComment,
  postCrispsDeleteComment,
  postCrispsUse,
  postCrispsQueryComments,
  postCrispsReportComment,
  postCrispsUncollect,
  postCrispsUnvoteCrisps,
  postCrispsView,
  postCrispsVoteCrisps,
} from "../../common/netWork/crisps_api";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import { postUserLogout, postUserMe } from "../../common/netWork/base_api";
import { doLogin, doLogout } from "../../common/netWork/base";

import UiUtil from "../../utils/UiUtil";
import Colors from "../../utils/Colors";
import dataTimeToFormats from "../../utils/timestampToTime";
import httpImageToPrefix from "../../utils/httpImageToPrefix";

import crisps from "../../assets/css/crisps/crisps.less";

import MindType from "../../viewmodel/datatype/MindType";
import MindElementType from "../../viewmodel/datatype/MindElementType";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";
import EditMindmapVMs from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import ShoppingModel from "../../components/shopping/ShoppingModel";
import { getJwt } from "../../common/netWork/base";
import MindMe from '../../viewmodel/facade/MindMe';
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}

export default {
  inject: ["showSettingModel"],
  data() {
    return {
      spinning: false, //是否展示加载动画
      mindMapId: null,
      showLoginModelAccout: false, //登录模态框
      hideDgModelAccout: false, //大纲模态框
      showBg: false, //背景遮罩
      visible: false, //举报 删除悬浮框
      userDataModel: false, //用户信息模态框
      crispsMindData: {}, //导图数据
      crispsMindUserData: {}, //当前登录用户数据
      crispsCommentComponent: false, //评论组件
      commentForm: this.$form.createForm(this), //评论表单
      commentContent: [], //评论内容

      crispsClickId: "", //当前选中的id
      reportCrispsVisible: false, //评论举报模态框
      reportCrispsDelete: false, //评论删除模态框
      reportCrispsContentList: [
        "色情低俗",
        "广告骚扰",
        "政治宗教",
        "侵权（肖像、诽谤、抄袭、冒用）",
        "违禁内容",
        "造谣传谣",
      ],
      reportCrispsContentListNumber: 0, //举报选项
      reportCrispsClickContent: "色情低俗", //举报选中的内容
      commentName: "我也来评论一下吧~", //评论placehoder
      commentUserId: "", //回复的用户id
      commentIndex: "", //评论的索引
      commentIsEnd: true, //是否为最后一条评论
      userDataId: "", //当前登录用户Id
      httpAudio: "", //音频

      reportCrispsShareModel: false, //分享链接模态框
      logoSrc: require("../../assets/img/code/logo.png"), //分享链接二维码logo
      form: this.$form.createForm(this),
      shareCrispsLinkTitle: "",
      shareCrispsLinkSubTitle: "",
      modelAccout: false,
      titelAccout: 0, //大纲导图切换
      mobileShowOutlineMap: true, //移动端是否显示大纲导图按钮
      accoutList: [
        {
          styleName: getString(strings.Public_Header_Mind),
        },
        {
          styleName: getString(strings.Public_Header_Outline),
        },
      ], //大纲导图
      MindOperateUIControllerView: null,
      strings: strings,

      nowElectron: false, //是否是electron环境
      //electron环境下的弹窗
      showContactUs: false,
      showAboutProject: false,
      userMessage: false,
      isDarkColor: false,
      shoppingModel: false, //购买m豆
      showMbean: false, //显示购买m豆
      isMoble: false, //是否移动端
      isMember: false, //是否会员
      isSave: getString(strings.Mind_Group_Save), //是否保存
    };
  },
  components: {
    vueQr,
    ComplieHeader,
    ComplieShowImg,
    LoginComponents,
    LookUserMessage,
    ComplieAudioLeft,
    ComplieLeftOutline,
    ComplieLeftContent,
    ComplieAddModelRemark,
    ComplieLeftMenuShareTIps,
    AboutProject,
    SettingUpPopup,
    Usermessage,
    ComplieHelp,
    ShoppingModel,
  },
  created() {
    this.nowElectron = this.$tools.isElectron();
    //判断导图内容是否有音频信息
    if (this.$store.state.mp3Blob == "del") {
      this.httpAudio = "";
    } else {
      if (
        this.$store.state.canvanDatas == null ||
        this.$store.state.canvanDatas.audio == undefined
      ) {
        return;
      }
      this.httpAudio = this.$store.state.canvanDatas.audio;
    }
  },
  mounted() {
    //获取当前用户的数据
    let userMeData = JSON.parse(localStorage.getItem("meMessage"));
    if (userMeData) {
      this.isMember = userMeData.isMember;
    }
    if (this.nowElectron) {
      this.isLoginStatus();
      //判断当前状态是否是登录状态
      this.isLoginStatus();
      //mind+的菜单弹出的弹窗
      ipcRenderer.on("settingValMind", (event, message) => {
        //联系我们
        if (message == "SettingUpMind") {
          this.showContactUs = true;
        }
      });
      ipcRenderer.on("aboutMind", (event, message) => {
        //关于
        if (message == "aboutMind") {
          this.showAboutProject = true;
        }
      });
      ipcRenderer.on("setUpMind", (event, message) => {
        //设置
        if (message == "setUpMind") {
          this.showSettingModel();
        }
      });
      ipcRenderer.on("helpMind", (event, message) => {
        //帮助
        if (message == "helpMind") {
          this.helpModal(true);
        }
      });
      //我的

      ipcRenderer.on("personalInformationMind", (event, message) => {
        //查看个人信息

        if (message == "personalInformationMind") {
          this.showUserDataModel({
            accout: true,
            userId: userMeData.userId,
            xfrom: "crisps",
            fromId: userMeData.userId,
          });
        }
      });

      ipcRenderer.on("setInformationMind", (event, message) => {
        //设置信息
        if (message == "setInformationMind") {
          // this.helpModal(true);
          this.ShowBg = !this.ShowBg;
          this.userMessage = !this.userMessage;
        }
      });

      ipcRenderer.on("SignOutMind", (event, message) => {
        //退出登录
        if (message == "SignOutMind") {
          // this.helpModal(true);
          this.pushOutLogin();
          // this.isLoginStatus();
        }
      });
      ipcRenderer.on("GlobalLoginMind", (event, message) => {
        //登录注册
        if (message == "GlobalLoginMind") {
          // this.helpModal(true);
          // this.ShowLoginModel();
          // this.isLoginStatus();
          this.showLoginModelAccout = !this.showLoginModelAccout;
          this.showBg = !this.showBg;
        }
      });

      ipcRenderer.on("judgeLoginStatus", (event, message) => {
        //撤销
        if (!message) {
          location.reload();
        }
      });
      ipcRenderer.on('mindmapReview', (event, message) => {//导图回顾
        if(message == 'mindmapReview'){
          this.mindmapPlayPauseShowSetup({
            showSetup: false,
            playAccout: "SHOW_PLAY_SETUP",
          });
        }
      });
    }
    if (this.$route.query.sheetId == null || this.$route.query.sheetId == "") {
      this.postCrispsView();
    } else {
      this.postSheetCrispsView();
    }
    this.getTitle();
    const userData = localStorage.getItem("meMessage");
    if (userData != "" && userData != null) {
      this.userDataId = JSON.parse(userData);
      this.crispsMindUserData = this.userDataId;
    }
    var that = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState == "visible") {
        let userData = localStorage.getItem("meMessage");
        if (userData != null) {
          that.postUserMe();
          return;
        }
      }
    });
    let client_width = document.documentElement.clientWidth;
    if (client_width <= 1024) {
      this.isMoble = true;
    }
  },
  methods: {
    ...mapMutations([
      "showLoginModel",
      "showUserDataModel",
      "showUserDataModel",
      "isShowShoppingModel",
      "mindmapPlayPauseShowSetup",
      "helpModal",
      "detailsToUserMessageModel",
    ]),
    getString(i) {
      return getString(i);
    },
    //最多输入48个中文
    checkLength: function (e, maxLength) {
      let dom = e.srcElement;
      let l = 0;
      for (let i = 0; i < dom.value.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(dom.value[i])) {
          l += 2;
        } else {
          l++;
        }
        if (l > maxLength) {
          dom.value = dom.value.substr(0, i);
          break;
        }
      }
    },
    postUserMe() {
      postUserMe(
        {},
        (res) => {
          doLogin(res);
          localStorage.setItem("meMessage", JSON.stringify(res));
        },
        (e) => {}
      );
    },
    //气泡时间线处理
    getHeaderMenuAccout(borderAccoutData) {
      if (
        borderAccoutData.mindType == MindType.DOUBLE_BUBBLE_MAP ||
        borderAccoutData.mindType == MindType.BUBBLE_MAP ||
        borderAccoutData.mindType == MindType.TIME_MAP
      ) {
        this.mobileShowOutlineMap = false;
      }else{
        this.mobileShowOutlineMap = true;
      }
      this.mindmapIsOutline();
    },
    // 大纲&导图
    setAccoutTitel(index) {
      if (this.accoutList.length == 1) {
        return;
      }
      //大纲
      this.titelAccout = index;
      this.MindOperateUIControllerView = new MindOperateUIControllerView(
        EditMindmapVM,
        EditMindmapVM
      );
      this.MindOperateUIControllerView.onSelected(MindElementType.OUTLINE);
      this.mindmapIsOutline();
    },
    //当前是否为大纲
    mindmapIsOutline() {
      this.$nextTick(function () {
        let titleDom = document.getElementsByClassName("crisps-detial-header");
        let outlineIcon = document.getElementsByClassName("mindmap-or-outline");
        let isMobile = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        if (titleDom[0] == undefined) {
          return;
        }
        if (!isMobile) {
          outlineIcon[0].style.display = "none";
        }
        if (
          isMobile &&
          EditMindmapVM.mindDisplayType == MindDisplayType.Outline
        ) {
          titleDom[0].style.backgroundColor = "#FFF2F2";
        } else {
          titleDom[0].style.backgroundColor = "transparent";
        }
      });
    },
    //请求知识圈导图信息
    postCrispsView() {
      this.spinning = true;
      postCrispsView(
        { crispsId: this.$router.history.current.params.id },
        (res) => {
          this.spinning = false;
          this.crispsMindData = res;
          this.checkAddIconColor(
            JSON.parse(this.crispsMindData.content).mindBGColor
          );
          this.crispsMindData.userAvatar = httpImageToPrefix(res.userAvatar);
          this.postCrispsQueryComments();
        },
        (error) => {
          this.spinning = false;
        }
      );
    },
    //请求知识圈分页导图信息
    postSheetCrispsView() {
      this.spinning = true;
      postCrispsView(
        {
          crispsId: this.$router.history.current.params.id,
          sheetId: this.$router.history.current.query.sheetId,
        },
        (res) => {
          this.spinning = false;
          this.crispsMindData = res;
          this.checkAddIconColor(
            JSON.parse(this.crispsMindData.content).mindBGColor
          );
          this.crispsMindData.userAvatar = httpImageToPrefix(res.userAvatar);
          this.postCrispsQueryComments();
        },
        (error) => {
          this.spinning = false;
        }
      );
    },
    //请求知识圈评论
    postCrispsQueryComments(index, toCommentId = "", lastId = "") {
      postCrispsQueryComments(
        { crispsId: this.crispsMindData.crispsId, toCommentId, lastId },
        (res) => {
          /**
           * 默认展示评论 lastId == ""
           * 展示更多评论 else
           */
          let comments = res.comments;
          if (comments && comments.length > 0) {
            if (lastId == "") {
              for (let i = 0; i < res.comments.length; i++) {
                const userAvatar = res.comments[i].userAvatar;
                res.comments[i].userAvatar = httpImageToPrefix(userAvatar);
                for (let j = 0; j < res.comments[i].topComments.length; j++) {
                  const userAvatar = res.comments[i].topComments[j].userAvatar;
                  res.comments[i].topComments[j].userAvatar =
                    httpImageToPrefix(userAvatar);
                }
              }
              this.commentContent = res.comments;
            } else {
              /**
               * 最后一条数据 isEnd == true
               */
              this.commentContent[index].isEnd = res.isEnd;
              for (let i = 0; i < res.comments.length; i++) {
                const userAvatar = res.comments[i].userAvatar;
                res.comments[i].userAvatar = httpImageToPrefix(userAvatar);
                let c = res.comments[i];
                c.userAvatar = httpImageToPrefix(c.userAvatar);
                this.commentContent[index].topComments.push(c);
              }
            }
          }
        }
      );
    },
    //风暴点赞
    postCrispsVoteCrisps(id) {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      //voteCrispsAccout true 为取消点赞 false 为点赞
      let voteCrispsAccout = this.crispsMindData.voted;
      if (voteCrispsAccout) {
        postCrispsUnvoteCrisps(
          { crispsId: id },
          (res) => {
            if (this.crispsMindData.voteCount != 0) {
              this.crispsMindData.voteCount -= 1;
            }
            this.crispsMindData.voted = false;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else {
        postCrispsVoteCrisps(
          { crispsId: id },
          (res) => {
            this.crispsMindData.voteCount += 1;
            this.crispsMindData.voted = true;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    //评论组件
    crispsComment() {
      this.crispsCommentComponent = !this.crispsCommentComponent;
    },
    //风暴收藏
    postCrispsCollect(id) {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      //voteCrispsAccout true 为取消点赞 false 为点赞
      let collected = this.crispsMindData.collected;
      // console.log(collected);
      if (collected) {
        postCrispsUncollect(
          { crispsId: id },
          (res) => {
            this.crispsMindData.collected = false;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else {
        postCrispsCollect(
          { collectionId: "", crispsId: id },
          (res) => {
            this.crispsMindData.collected = true;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    //风暴分享模态框
    srispsShare() {
      this.reportCrispsShareModel = true;
      //获取主副标题
      this.shareCrispsLinkTitle = EditMindmapVMs.getTitle();
      this.shareCrispsLinkSubTitle = EditMindmapVMs.getSubtitle();
    },
    //风暴分享
    commentCrispsShareHandleOk() {
      // let userData = localStorage.getItem("meMessage");
      // if (userData == null || userData == "") {
      //   this.showLoginModel(true);
      //   return;
      // }
      const titel =
        this.shareCrispsLinkTitle +
        "\n" +
        this.shareCrispsLinkSubTitle +
        "\n# " +
        this.crispsMindData.link;

      var input = document.createElement("textarea"); // 直接构建input
      input.value = titel; // 设置内容 需要复制的内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success(getString(strings.Message_Tips_Copy_Success));
    },
    //通过评论展示个人中心
    commentToUserData(userData) {
      if (new UiUtil().isPhoneAndPad()) {
        return;
      }
      let userId = userData.userId;
      let xfrom = "comment";
      let fromId = userData.userId;
      this.showUserDataModel({ accout: true, userId, xfrom, fromId });
    },
    //风暴 举报 删除
    reportCrisps(index, id, replyMessage) {
      const type = replyMessage.type;
      if (type == "replyDelete" || type == "commentDelete") {
        if (type == "commentDelete") {
          this.commentContent[index].visible = false;
        } else if (type == "replyDelete") {
          this.commentContent[index].topComments[
            replyMessage.index
          ].visible = false;
        }
        this.reportCrispsDelete = true;
      } else if (type == "reply" || type == "comment") {
        if (type == "comment") {
          this.commentContent[index].visible = false;
        } else if (type == "reply") {
          this.commentContent[index].topComments[
            replyMessage.index
          ].visible = false;
        }
        this.reportCrispsVisible = true;
        this.commentContent[index].visible = false;
      }
      this.crispsClickId = { index, id, replyMessage };
    },
    //举报选项
    reportCrispsClick(index = 0) {
      this.reportCrispsContentListNumber = index;
      this.reportCrispsClickContent = this.reportCrispsContentList[index];
    },
    //风暴评论举报确定
    reportCrispsHandleOk() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.reportCrispsVisible = false;
      const commentId = this.crispsClickId.id;
      const reason = this.reportCrispsClickContent;
      const type = this.crispsClickId.replyMessage.type;
      const commentIndex = this.crispsClickId.index;
      const replyIndex = this.crispsClickId.replyMessage.index;
      // console.log(commentId, reason);
      postCrispsReportComment(
        { commentId, reason },
        (res) => {
          //回复举报reply
          if (type == "reply") {
            this.commentContent[commentIndex].commentCount =
              this.commentContent[commentIndex].commentCount - 1;
            this.commentContent[commentIndex].topComments.splice(replyIndex, 1);
          } else if (type == "comment") {
            this.commentContent.splice(commentIndex, 1);
          }
          this.$message.success(getString(strings.Message_Tips_Report_Success));
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //风暴评论
    commentSubmit(e) {
      e.preventDefault();
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.commentForm.validateFields((err, stormComment) => {
        if (!err) {
          postCrispsComment(
            {
              crispsId: this.crispsMindData.crispsId,
              toCommentId: this.commentUserId,
              content: stormComment.commentContent,
            },
            (res) => {
              //回复评论id: toCommentId
              if (this.commentUserId != "") {
                const userAvatar = res.userAvatar;
                res.userAvatar = httpImageToPrefix(userAvatar);
                this.commentContent[this.commentIndex].topComments.unshift(res);
                this.commentContent[this.commentIndex].commentCount =
                  this.commentContent[this.commentIndex].commentCount + 1;
                this.commentForm.setFieldsValue({
                  commentContent: "",
                });
                this.commentUserId = "";
                this.commentName = "我也来评论一下吧~";
              } else {
                const userAvatar = res.userAvatar;
                res.userAvatar = httpImageToPrefix(userAvatar);
                this.commentContent.unshift(res);
                this.commentForm.setFieldsValue({
                  commentContent: "",
                });
                this.commentName = "我也来评论一下吧~";
              }
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        }
      });
    },
    //回复评论
    commentReply(index, item) {
      this.commentName = "回复@" + item.username + ":";
      this.commentUserId = item.id;
      this.commentIndex = index;
      this.$refs.commentInput.focus();
    },
    //风暴删除评论确定
    commentCrispsDeleteHandleOk() {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.reportCrispsDelete = false;
      const commentId = this.crispsClickId.id;
      const reason = this.reportCrispsClickContent;
      const type = this.crispsClickId.replyMessage.type;
      const commentIndex = this.crispsClickId.index;
      const replyIndex = this.crispsClickId.replyMessage.index;
      postCrispsDeleteComment(
        { commentId },
        (res) => {
          this.$message.success(getString(strings.Message_Tips_Dlt_Success));
          if (type == "replyDelete") {
            this.commentContent[commentIndex].commentCount =
              this.commentContent[commentIndex].commentCount - 1;
            this.commentContent[commentIndex].topComments.splice(replyIndex, 1);
          } else if (type == "commentDelete") {
            this.commentContent.splice(commentIndex, 1);
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //展开更多回复评论
    moreReply(index, toCommentId) {
      const lastId =
        this.commentContent[index].topComments[
          this.commentContent[index].topComments.length - 1
        ].id;
      this.postCrispsQueryComments(index, toCommentId, lastId);
    },
    //功能按钮颜色
    checkAddIconColor(btnColor) {
      this.$nextTick(function () {
        let isDarkColor = (this.isDarkColor = Colors.isLightColor(btnColor));
        let color = "";
        if (isDarkColor) {
          color = "#666";
        } else {
          color = "#fff";
        }
        let ele = document.querySelectorAll(".iconfont");
        let nameColor = document.getElementsByClassName("user-name");
        var btnTextLinkColor =
          document.getElementsByClassName("crisps-like-text");
        var btnTextCommentColor =
          document.getElementsByClassName("crisps-like");
        let btnMindIconColor =
          document.getElementsByClassName("mindmap-or-outline")[0];
        let btnMindPlayColor =
          document.getElementsByClassName("mindmap-play")[0];
        if (nameColor[0]) {
          nameColor[0].style.color = color;
        }
        if (btnTextLinkColor[0]) {
          btnTextLinkColor[0].style.color = color;
        }
        if (btnMindIconColor != undefined) {
          btnMindIconColor.getElementsByTagName("span")[0].style.color = color;
        }
        if (btnMindPlayColor != undefined) {
          btnMindPlayColor.getElementsByTagName("span")[0].style.color = color;
        }

        for (let i = 0; i < btnTextCommentColor.length; i++) {
          if (btnTextCommentColor[i]) {
            btnTextCommentColor[i].style.color = color;
          }
        }
        for (let i = 0; i < ele.length; i++) {
          if (ele[i]) {
            ele[i].style.color = color;
          }
        }
      });
    },
    //显示用户中心
    getUserProfile() {
      if (new UiUtil().isPhoneAndPad()) {
        return;
      }
      let userId = this.crispsMindData.userId;
      let xfrom = "crisps";
      let fromId = this.crispsMindData.userId;
      this.showUserDataModel({ accout: true, userId, xfrom, fromId });
    },
    //浏览器Title
    getTitle() {
      setTimeout(() => {
        document.title = EditMindmapVM.getTitle() + " - 寻简思维导图";
      }, 100);
    },
    //时间处理
    formatTime(time) {
      return dataTimeToFormats.formatTime(time);
    },
    //登录状态
    loginStatus() {
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return false;
      } else {
        return true;
      }
    },
    //导图播放
    mindmapPlay() {
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "SHOW_PLAY_SETUP",
      });
    },
    //保存
    paymentUse() {
      if (this.loginStatus()) {
        if (this.crispsMindData.mbeansRequired != 0) {
          let that = this;
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content:
              getString(strings.Mind_Shooping_Confirm_Payment) +
              this.crispsMindData.mbeansRequired +
              getString(strings.Mind_MBean),
            centered: true,
            okText: getString(strings.Global_Ok),
            cancelText: getString(strings.Global_Cancel),
            onOk() {
              that.sendPostCrispsUse();
            },
            onCancel() {},
          });
        } else {
          this.sendPostCrispsUse();
        }
      }
    },
    sendPostCrispsUse() {
      this.spinning = true;
      const obj = {
        crispsId: this.$router.history.current.params.id,
      };
      postCrispsUse(
        obj,
        (res) => {
          this.spinning = false;
          this.$message.success(getString(strings.Message_Tips_Save_Success));
          this.isSave = getString(strings.Header_Mind_Tips_Saved);
        },
        (error) => {
          this.spinning = false;
          // console.log(error);
          if (error.code == 152 && !this.isMoble) {
            //M豆数量不足
            let that = this;
            this.$confirm({
              title: getString(strings.Mind_Tips),
              content: getString(
                strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean
              ),
              centered: true,
              okText: getString(strings.Global_Ok),
              cancelText: getString(strings.Global_Cancel),
              onOk() {
                that.showMbean = true;
                that.ShowShoppingModel();
              },
              onCancel() {},
            });
            return;
          }
          if (error.code == 94 && !this.isMoble) {
            //需要会员
            let that = this;
            this.$confirm({
              title: getString(strings.Mind_Tips),
              content:
                error.desc +
                "，" +
                getString(strings.Mind_Edit_Left_Menu_Open_Membership),
              centered: true,
              okText: getString(strings.Global_Ok),
              cancelText: getString(strings.Global_Cancel),
              onOk() {
                that.showMbean = false;
                that.ShowShoppingModel();
              },
              onCancel() {},
            });
            return;
          }
          this.$message.error(error.desc);
        }
      );
    },
    //购物车模块
    ShowShoppingModel() {
      this.shoppingModel = !this.shoppingModel;
      this.showBg = !this.showBg;
    },
    HideShoppingModel(Account) {
      this.shoppingModel = Account;
      this.showBg = false;
      this.isShowShoppingModel(false);
    },
    //当前导图模式
    getMindmapPattern() {
      return EditMindmapVM.mindDisplayType == MindDisplayType.MindMap
        ? true
        : false;
    },
    //判断当前登录状态
    isLoginStatus() {
      let languages = {
        eleAbout: getString(this.strings.Mind_About),
        eleSetting: getString(this.strings.Mind_Group_Setting_Up),
        eleHelp: getString(this.strings.Mind_Edit_Left_Menu_Help),
        eleContactUs: getString(this.strings.Setting_Up),
        eleMine: getString(this.strings.Mind_Edit_Mine),
        eleSee: getString(this.strings.See_Checking_Data),
        eleModify: getString(this.strings.Modify_Checking_Data),
        eleSignOut: getString(this.strings.Sign_Out),
        eleLogin:
          getString(this.strings.User_Lolgin_Registered) +
          "/" +
          getString(this.strings.User_Lolgin_Login),
        eleEdit: getString(this.strings.Global_Edit),
        eleRevoke: getString(this.strings.Mind_Edit_Revoke),
        eleRestore: getString(this.strings.Mind_Edit_Restore),
        eleCut: getString(this.strings.Mind_Shear),
        eleCopy: getString(this.strings.Mind_Edit_Node_Menu_Copy),
        elePaste: getString(this.strings.Mind_Edit_Node_Menu_Paste),
        eleSelectAll: getString(this.strings.Mind_Group_Check_All),
        find: getString(this.strings.Global_Text_Find),         
        see: getString(this.strings.Mind_See),
        outline: getString(this.strings.Public_Header_Outline),
        enlarge: getString(this.strings.Mind_Enlarge),
        narrow: getString(this.strings.Mind_Narrow),
        style: getString(this.strings.Mind_Edit_Right_Style),
        mindmapReview: getString(this.strings.Mindmap_Review),
      };
      if (!MindMe.isLogin()) {
        ipcRenderer.send("isMindSignIn", false, languages);
      } else {
        ipcRenderer.send("isMindSignIn", true, languages);
      }
    },
    showAboutModal(obj) {
      //隐藏弹框
      // if(obj.show == false) {
      this.showAboutProject = obj.show;
      // }
      if (obj.isVersion != undefined) {
        // obj.isVersion
        this.versionUpdating({}).then((res) => {
          //请求最新版本号
          // res = {
          //   newVersion:'新版本2.0.0',
          //   desc:'内容详情', //更新内容详情
          //   url:'https://r.mindyushu.com/dl/release/windows/mindmap_windows_v2.0.0_amd64.exe', //安装包下载链接
          //   isDirectDownloadLink: false //指明上面的Url是否是下载直链（如果为false，则需要跳转到浏览器手动点下载）
          // }
          if (res.newVersion == "") {
            //当前是最新版本
            this.isLatestVersion = true;
            this.$message.success("当前已是最新版本");
          } else {
            //当前不是最新版本
            this.isLatestVersion = false;
            this.setVersionUpdatingModal(res);
            this.showAboutProject = false; // 隐藏版本信息页面
          }
        });
      }
    },
    showContactModal(val) {
      this.showContactUs = val;
    },
    HideUserMessage(Account) {
      this.ShowBg = !this.ShowBg;
      this.userMessage = Account;
      this.detailsToUserMessageModel(false);
    },
    //退出登录
    pushOutLogin() {
      postUserLogout(
        {},
        (data) => {},
        (e) => {
          console.log("退出失败");
        },
        () => {
          doLogout();
          //刷新群组页面信息
          if (this.nowElectron) {
            this.isLoginStatus();
          }
        }
      );
    },
  },
  watch: {
    $route: {
      handler() {
        this.postSheetCrispsView();
        // this.headerMenu = false;
        // this.hideDgModelAccout =
        // setTimeout(()=>{
        //   this.headerMenu = true;
        // },10)
      },
      deep: true,
    },
    "$store.state.hideModel"(newHideModel) {
      this.hideModelAccout = newHideModel;
    },
    "$store.state.showLogin"(newLoginShowLogin) {
      this.showLoginModelAccout = newLoginShowLogin;
      this.showBg = newLoginShowLogin;
      this.$store.state.useShortcut = !newLoginShowLogin; //是否可以使用快捷键
    },
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      this.hideDgModelAccout = newshowDaGangModels;
      if (newshowDaGangModels) {
        this.checkAddIconColor(16777215);
      } else {
        this.checkAddIconColor(
          JSON.parse(this.crispsMindData.content).mindBGColor
        );
      }
      //this.showBg = newshowDaGangModels;
    },
    "$store.state.userModelData.accout"(newShowUserModelData) {
      this.userDataModel = newShowUserModelData;
      this.showBg = newShowUserModelData;
    },
    "$store.state.canvanDatas.audio"(newHttpAudioData) {
      if (newHttpAudioData != "") {
        this.httpAudio = newHttpAudioData;
      }
    },
    "$store.state.showAddRemark"(newAddRemarkAccout, olAddRemarkAccout) {
      this.modelAccout = newAddRemarkAccout;
    },
    "$store.state.initData"(newInitData, olInitData) {
      this.getHeaderMenuAccout(newInitData);
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}

// 导图播放Icon
@font-face {
  font-family: "iconfontMindmapPlay";
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot");
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mindmap_play/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mindmap_play/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-mindmap-play {
  font-family: "iconfontMindmapPlay" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Outline";
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot");
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/outline_Icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/outline_Icon/iconfont.svg#iconfont")
      format("svg");
}
.Outline {
  font-family: "Outline" !important;
  font-size: 21px;
  font-style: normal;
  color: #666666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "mindicon";
  src: url("../../assets/font-icon/mind_icon/iconfont.eot");
  src: url("../../assets/font-icon/mind_icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mind_icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mind_icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mind_icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mind_icon/iconfont.svg#iconfont") format("svg");
}
.mindicon {
  font-family: "mindicon" !important;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
}

@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_shareStrom_icon/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/font_shareStrom_icon/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../assets/font-icon/font_shareStrom_icon/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_shareStrom_icon/iconfont.woff2")
      format("woff"),
    url("../../assets/font-icon/font_shareStrom_icon/iconfont.ttf")
      format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/font_shareStrom_icon/iconfont.ttf#iconfont")
      format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_commend/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/font_commend/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/font-icon/font_commend/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_commend/iconfont.woff2") format("woff"),
    url("../../assets/font-icon/font_commend/iconfont.ttf") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/font_commend/iconfont.ttf#iconfont")
      format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/crisps/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/crisps/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/font-icon/crisps/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/crisps/iconfont.woff2") format("woff"),
    url("../../assets/font-icon/crisps/iconfont.ttf") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/crisps/iconfont.ttf#iconfont") format("svg"); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.icon-aixin-before {
  color: #f78e7b !important;
  transition: 0.3s;
}
.icon-shoucang-befor {
  color: #f78e7b !important;
  transition: 0.3s;
}

.icon-pinglun {
  font-size: 20px;
}

.template {
  min-width: 1200px;
}
/* 加载动画 */
.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 300;
}
.loading-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2);
}
.ant-spin-dot-item {
  background-color: #fd492b;
}
.ant-spin {
  color: #fd492b;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fd492b !important;
}
.Shopping-Father-Box {
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#components-layout-demo-side {
  .comlie-left-content {
    .complie-left-audio {
      z-index: 10;
      position: absolute;
      left: 0px;
      height: 46px;
      opacity: 1;
      border-radius: 61px;
      transition: 0.5s;
      font-size: 16px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 140px;
    }
    .complie-left-audio input {
      border: none;
    }
    .crisps-detial-header {
      min-height: 26px;
      position: absolute;
      min-width: 780px;
      left: 50%;
      top: 95px;
      transform: translate(-50%, 20%);
     /* top: 20px;
      right: 55px;*/
      z-index: 100;
      display: flex;
      align-items: center;
      justify-content: center;
      .crisps-user-message {
        display: flex;
        align-items: center;
        margin-right: 50px;
        cursor: pointer;
        .user-header {
          border: 1px solid #ffffff;
          border-radius: 50%;
          // box-shadow: 0px 3px 6px #dedede;
          img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
          }
        }
        .userIsMember {
          display: flex;
          justify-content: center;
          background-image: url(../../assets/img/user/crown.png);
          background-repeat: no-repeat;
          width: 34px;
          height: 42px;
          margin: 0 auto;
          position: relative;
          top: -4px;
          right: 5px;
          img {
            position: absolute;
            top: 10px;
            width: 34px;
            height: 34px;
            border-radius: 50%;
          }
        }
        .user-name {
          margin-left: 10px;
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 22px;
          color: #666666;
          opacity: 1;
          white-space: nowrap;
        }
      }
      .crisps-detials-handle {
        display: flex;
        align-items: center;
        .mindmap-play {
          margin-right: 40px;
          text-align: center;
          cursor: pointer;
        }
        .payment-use {
          // width: 110px;
          height: 34px;
          padding: 0 25px;
          font-size: 16px;
          color: #fff;
          background: #fd492b;
          border: 0;
          cursor: pointer;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          .m-img {
            margin: 0 10px 0 3px;
          }
        }
        //css隐藏大纲按钮
        .mindmap-or-outline {
          display: none;
          margin-right: 40px;
        }
        .crisps-like-box {
          min-width: 44px;
          margin-right: 40px;
          .crisps-like {
            cursor: pointer;
            display: flex;
            align-items: center;
            span:last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .comlie-right-menu-box {
    max-width: 420px;
    min-width: 320px;
    width: 100%;
    position: absolute;
    z-index: 500;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    height: calc(100vh - 80px);
    box-shadow: -6px 0px 10px rgba(0, 0, 0, 0.1);
    .storm-comment-box {
      margin: 30px 13px 20px;
      position: relative;
      height: calc(100vh - 134px);
      .storm-comment-text {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 25px;
        color: #333333;
        opacity: 1;
      }
      //评论
      .storm-user-comment {
        margin-top: 20px;
        height: calc(100vh - 270px);
        overflow: auto;
        width: 100%;
        //评论
        .storm-user-comment-message {
          .storm-user-comment-box {
            display: flex;
            min-height: 109px;
            border-bottom: 1px solid #ececec;
            align-items: center;
            flex-wrap: nowrap;
            .storm-user-header {
              width: 42px;
              height: 80px;
              margin-bottom: 12px;
              cursor: pointer;
              .storm-user-img {
                border: 1px solid #ffffff;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                img {
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                  margin-top: 12px;
                }
              }
              .storm-user-img-ismember {
                border: 1px solid #ffffff;
                border-radius: 50%;
                background-image: url(../../../static/img/userData/user_message_vip_bg.png);
                background-size: 100%;
                width: 42px;
                height: 52px;
                img {
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                  margin-top: 12px;
                }
              }
            }
            .storm-comment-content {
              // min-width: 322px;
              width: 80%;
              margin-left: 10px;
              .comment-content-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .comment-user-name {
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  font-weight: inherit;
                  line-height: 17px;
                  color: #333333;
                  opacity: 1;
                  max-height: 20px;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 60px;
                  }
                  .comment-author-logo {
                    margin-left: 26px;
                    padding: 3px 6px;
                    border-radius: 2px;
                    background: #ec705a;
                    color: #ffffff;
                  }
                }
                .comment-cotnent-detials {
                  button {
                    border: none;
                    padding: 0 10px;
                  }
                }
              }
              .comment-content-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .comment-content-text {
                  width: 266px;
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 20px;
                  color: #333333;
                  opacity: 1;
                }
              }
              .comment-content-reply {
                display: flex;
                justify-content: space-between;
                margin-top: 6px;
                .comment-time {
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 17px;
                  color: #999999;
                  opacity: 1;
                }
                .comment-reply {
                  cursor: pointer;
                  margin: 0;
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  /*font-weight: bold;*/
                  line-height: 17px;
                  color: #666666;
                  opacity: 1;
                }
              }
            }
          }
        }
        //回复
        .storm-comment-reply {
          border-bottom: 1px solid #ececec;
          padding: 0 0 20px 0;
          .storm-user-comment-message-reply {
            display: flex;
            align-items: center;
            margin-left: 50px;
            .storm-user-header {
              width: 42px;
              height: 80px;
              margin-bottom: 12px;
              cursor: pointer;
              .storm-user-img {
                border: 1px solid #ffffff;
                width: 42px;
                height: 42px;
                border-radius: 50%;
                img {
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                  margin-top: 12px;
                }
              }
              .storm-user-img-ismember {
                border: 1px solid #ffffff;
                border-radius: 50%;
                background-image: url(../../../static/img/userData/user_message_vip_bg.png);
                background-size: 100%;
                width: 42px;
                height: 52px;
                img {
                  width: 42px;
                  height: 42px;
                  border-radius: 50%;
                  margin-top: 12px;
                }
              }
            }
            .storm-comment-content {
              min-width: 270px;
              margin-left: 10px;
              .comment-content-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .comment-user-name {
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  font-weight: inherit;
                  line-height: 17px;
                  color: #333333;
                  opacity: 1;
                  max-height: 20px;
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 60px;
                  }
                  .comment-author-logo {
                    margin-left: 26px;
                    padding: 3px 6px;
                    border-radius: 2px;
                    background: #ec705a;
                    color: #ffffff;
                  }
                }
                .comment-cotnent-detials {
                  button {
                    border: none;
                    padding: 0 10px;
                  }
                }
              }
              .comment-content-box {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .comment-content-text {
                  width: 266px;
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 20px;
                  color: #333333;
                  opacity: 1;
                }
              }
              .comment-content-reply {
                display: flex;
                justify-content: space-between;
                margin-top: 6px;
                .comment-time {
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 17px;
                  color: #999999;
                  opacity: 1;
                }
                .comment-reply {
                  cursor: pointer;
                  margin: 0;
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  /*font-weight: bold;*/
                  line-height: 17px;
                  color: #666666;
                  opacity: 1;
                }
              }
            }
            //回复
            // .comment-reply-from{
            //   width: 100%;
            //   height: 50px;
            //   border: 1px solid red;
            //   margin: 0 13px;
            // }
          }
          .comment-reply-more {
            display: flex;
            margin-left: 50px;
            font-size: 12px;
            font-family: Noto Sans SC;
            font-weight: 400;
            line-height: 17px;
            color: #666666;
            opacity: 1;
            cursor: pointer;
            align-items: center;
            .more-comment-logo {
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 6px solid #666666;
              border-radius: 3px;
              margin-left: 6px;
            }
          }
        }
      }
      .storm-user-comment-nodata {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 600px;
        .nodata-box {
          img {
            width: 202px;
            height: 214px;
            margin-bottom: 20px;
          }
          p {
            font-size: 14px;
            font-family: Noto Sans SC;
            font-weight: 400;
            line-height: 20px;
            color: #333333;
            opacity: 1;
            text-align: center;
          }
        }
      }
      .storm-user-comment::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        /**/
      }
      .storm-user-comment::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
      }
      .storm-user-comment::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 10px;
      }
      .storm-user-comment::-webkit-scrollbar-thumb:hover {
        background: #b4b4b4;
        cursor: pointer;
      }
      .storm-user-form {
        position: absolute;
        bottom: 0;
        width: 100%;
        .ant-form {
          display: flex;
          justify-content: space-between;
          .ant-form-item {
            width: 100%;
          }
          input {
            width: 100%;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #c8c8c8;
          }
          input:focus {
            border: 1px solid #fd492b;
          }
          .storm-user-form-button {
            width: 67px;
            button {
              width: 67px;
              height: 40px;
              opacity: 1;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}
// ant design vue
.ant-popover {
  z-index: 1000;
}
.chips-delete-button {
  cursor: pointer;
}
.ant-popover-inner-content {
  padding: 18px 23px;
}
.chips-delete-content {
  display: flex;
  align-items: center;
  height: 40px;
  width: 146px;
  cursor: pointer;
  span {
    margin-left: 10px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    line-height: 24px;
    color: #666666;
  }
}
</style>

<style lang="less" scoped>
//移动端
@media screen and(min-width:320px) and(max-width:586px) {
  .iconfont {
    font-size: 20px;
  }
  .icon-weibiaoti {
    font-size: 21px !important;
  }
  .icon-shoucang {
    font-size: 20px !important;
  }
  .share-icon {
    font-size: 21px !important;
  }
  .complie-box {
    .comlie-center-content {
      #components-layout-demo-side {
        .comlie-left-content {
          .crisps-detial-header {
            // background-color: #fff2f2;
            min-width: 0px;
            min-height: 26px;
            position: absolute;
            left: 50%; //52%;
            top: -10px;
            transform: translate(-50%, 20%);
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: start;
            width: 100%; //96%;
            height: 50px;
            padding: 10px;
            .crisps-user-message {
              display: flex;
              align-items: center;
              margin-right: 10px;
              min-width: 85px;
              cursor: pointer;
              .user-header {
                border: 1px solid #ffffff;
                border-radius: 50%;
                img {
                  width: 34px;
                  height: 34px;
                  border-radius: 50%;
                }
              }
              .userIsMember {
                display: flex;
                justify-content: center;
                background-image: url(../../assets/img/user/crown.png);
                background-repeat: no-repeat;
                width: 26px;
                height: 36px;
                margin: 0 auto;
                position: relative;
                top: -4px;
                right: 5px;
                background-size: 100%;
                img {
                  position: absolute;
                  top: 8px;
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                }
              }
              .user-name {
                margin-left: 10px;
                font-size: 14px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 22px;
                color: #666666;
                opacity: 1;
                max-width: 69px;
                overflow: hidden;
                height: 22px;
                // display: none;
              }
            }
            .crisps-detials-handle {
              display: flex;
              align-items: center;
              position: absolute;
              right: 0;
              .mindmap-play {
                margin-right: 10px;
                text-align: center;
                cursor: pointer;
                margin-bottom: 2px;
              }
              .payment-use {
                font-size: 14px;
                padding: 0 10px;
                padding: 0 15px;
                .m-img {
                  margin: 0 6px 0 2px;
                }
              }
              .mindmap-or-outline {
                display: block;
                border-radius: 19px;
                margin-right: 10px;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
              }
              .crisps-like-box {
                min-width: 30px;
                margin-right: 5px;
                .crisps-like {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  span:last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          .complie-left-audio {
            z-index: 10;
            position: absolute;
            top: 92px;
            left: 0px;
            height: 46px;
            opacity: 1;
            border-radius: 61px;
            transition: 0.5s;
            font-size: 16px;
            color: #666666;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .complie-left-audio input {
            border: none;
          }
        }
        .comlie-right-menu-box {
          height: calc(100vh - 280px);
          position: absolute;
          z-index: 500;
          left: 0;
          right: 0;
          bottom: 0px;
          max-width: 100%;
          .storm-comment-box {
            margin: 0;
            position: relative;
            height: 100%;
            .storm-comment-text {
              display: flex;
              justify-content: center;
              font-size: 18px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 25px;
              color: #333333;
              opacity: 1;
            }
            //评论
            .storm-user-comment {
              margin-top: 20px;
              height: calc(100vh - 376px);
              overflow-y: auto;
              //评论
              .storm-user-comment-message {
                .storm-user-comment-box {
                  display: flex;
                  min-height: 109px;
                  border-bottom: 1px solid #ececec;
                  align-items: center;
                  flex-wrap: nowrap;
                  .storm-user-header {
                    width: 42px;
                    height: 80px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    .storm-user-img {
                      border: 1px solid #ffffff;
                      width: 42px;
                      height: 42px;
                      border-radius: 50%;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                    .storm-user-img-ismember {
                      border: 1px solid #ffffff;
                      border-radius: 50%;
                      background-image: url(../../../static/img/userData/user_message_vip_bg.png);
                      background-size: 100%;
                      width: 42px;
                      height: 52px;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                  }
                  .storm-comment-content {
                    min-width: 200px;
                    margin-left: 10px;
                    max-width: 984px;
                    width: 100%;
                    .comment-content-header {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .comment-user-name {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: inherit;
                        line-height: 17px;
                        color: #333333;
                        opacity: 1;
                        max-height: 20px;
                        span {
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: 60px;
                          overflow: hidden;
                        }
                        .comment-author-logo {
                          margin-left: 26px;
                          padding: 3px 6px;
                          border-radius: 2px;
                          background: #ec705a;
                          color: #ffffff;
                        }
                      }
                      .comment-cotnent-detials {
                        button {
                          border: none;
                          padding: 0 10px;
                        }
                      }
                    }
                    .comment-content-box {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .comment-content-text {
                        width: 266px;
                        font-size: 14px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #333333;
                        opacity: 1;
                      }
                    }
                    .comment-content-reply {
                      display: flex;
                      justify-content: space-between;
                      margin-top: 6px;
                      .comment-time {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 17px;
                        color: #999999;
                        opacity: 1;
                      }
                      .comment-reply {
                        cursor: pointer;
                        margin: 0;
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        /*font-weight: bold;*/
                        line-height: 17px;
                        color: #666666;
                        opacity: 1;
                      }
                    }
                  }
                }
              }
              //回复
              .storm-comment-reply {
                border-bottom: 1px solid #ececec;
                padding: 0 0 20px 0;
                .storm-user-comment-message-reply {
                  display: flex;
                  align-items: center;
                  margin-left: 50px;
                  .storm-user-header {
                    width: 42px;
                    height: 80px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    .storm-user-img {
                      border: 1px solid #ffffff;
                      width: 42px;
                      height: 42px;
                      border-radius: 50%;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                    .storm-user-img-ismember {
                      border: 1px solid #ffffff;
                      border-radius: 50%;
                      background-image: url(../../../static/img/userData/user_message_vip_bg.png);
                      background-size: 100%;
                      width: 42px;
                      height: 52px;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                  }
                  .storm-comment-content {
                    min-width: 200px;
                    margin-left: 10px;
                    max-width: 984px;
                    width: 100%;
                    .comment-content-header {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .comment-user-name {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: inherit;
                        line-height: 17px;
                        color: #333333;
                        opacity: 1;
                        max-height: 20px;
                        span {
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: 60px;
                        }
                        .comment-author-logo {
                          margin-left: 26px;
                          padding: 3px 6px;
                          border-radius: 2px;
                          background: #ec705a;
                          color: #ffffff;
                        }
                      }
                      .comment-cotnent-detials {
                        button {
                          border: none;
                          padding: 0 10px;
                        }
                      }
                    }
                    .comment-content-box {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      min-width: 200px;
                      max-width: 322px;
                      width: 100%;
                      .comment-content-text {
                        width: 266px;
                        font-size: 14px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #333333;
                        opacity: 1;
                      }
                    }
                    .comment-content-reply {
                      display: flex;
                      justify-content: space-between;
                      margin-top: 6px;
                      .comment-time {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 17px;
                        color: #999999;
                        opacity: 1;
                      }
                      .comment-reply {
                        cursor: pointer;
                        margin: 0;
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        /*font-weight: bold;*/
                        line-height: 17px;
                        color: #666666;
                        opacity: 1;
                      }
                    }
                  }
                  //回复
                  // .comment-reply-from{
                  //   width: 100%;
                  //   height: 50px;
                  //   border: 1px solid red;
                  //   margin: 0 13px;
                  // }
                }
                .comment-reply-more {
                  display: flex;
                  margin-left: 50px;
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 17px;
                  color: #666666;
                  opacity: 1;
                  cursor: pointer;
                  align-items: center;
                  .more-comment-logo {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 6px solid #666666;
                    border-radius: 3px;
                    margin-left: 6px;
                  }
                }
              }
            }
            .storm-user-comment-nodata {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 219px;
              .nodata-box {
                img {
                  width: 156px;
                  height: 164px;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 20px;
                  color: #333333;
                  opacity: 1;
                  text-align: center;
                }
              }
            }
            .storm-user-comment::-webkit-scrollbar {
              width: 10px;
              height: 10px;
              /**/
            }
            .storm-user-comment::-webkit-scrollbar-track {
              background: rgb(239, 239, 239);
              border-radius: 2px;
            }
            .storm-user-comment::-webkit-scrollbar-thumb {
              background: #bfbfbf;
              border-radius: 10px;
            }
            .storm-user-comment::-webkit-scrollbar-thumb:hover {
              background: #b4b4b4;
              cursor: pointer;
            }
            .storm-user-form {
              position: absolute;
              bottom: 0;
              width: 100%;
              .ant-form {
                display: flex;
                justify-content: space-between;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 0;
                }
                input {
                  width: 100%;
                  height: 40px;
                  border-radius: 4px;
                  border: 1px solid #c8c8c8;
                }
                input:focus {
                  border: 1px solid #fd492b;
                }
                .storm-user-form-button {
                  width: 67px;
                  button {
                    width: 67px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .complie-box-model {
    margin-top: -80px;
    transition: 0.5s;
  }
  .comlie-content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

@media screen and(min-width:375px) and(max-width:667px) and (-webkit-device-pixel-ratio: 2) {
  .complie-box {
    .comlie-center-content {
      #components-layout-demo-side {
        .comlie-right-menu-box {
          height: calc(100vh - 280px);
          position: absolute;
          z-index: 500;
          left: 0;
          right: 0;
          bottom: 0px;
          max-width: 100%;
          .storm-comment-box {
            //评论
            .storm-user-comment {
              margin-top: 20px;
              height: calc(100vh - 376px);
              overflow-y: auto;
              //评论
              .storm-user-comment-message {
                .storm-user-comment-box {
                  .storm-user-header {
                    cursor: pointer;
                  }
                }
              }
            }
            .storm-user-comment-nodata {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 253px;
              .nodata-box {
                img {
                  width: 202px;
                  height: 214px;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 20px;
                  color: #333333;
                  opacity: 1;
                  text-align: center;
                }
              }
            }
            .storm-user-form {
              position: absolute;
              bottom: 0;
              width: 100%;
              .ant-form {
                display: flex;
                justify-content: space-between;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 0;
                }
                input {
                  width: 100%;
                  height: 40px;
                  border-radius: 4px;
                  border: 1px solid #c8c8c8;
                }
                input:focus {
                  border: 1px solid #fd492b;
                }
                .storm-user-form-button {
                  width: 67px;
                  button {
                    width: 67px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .complie-box-model {
    margin-top: -80px;
    transition: 0.5s;
  }
  .comlie-content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

@media screen and(min-width:375px) and(max-width:812px) and (-webkit-device-pixel-ratio: 3) {
  .complie-box {
    .comlie-center-content {
      #components-layout-demo-side {
        .comlie-right-menu-box {
          height: calc(100vh - 280px);
          position: absolute;
          z-index: 500;
          left: 0;
          right: 0;
          bottom: 0px;
          max-width: 100%;
          .storm-comment-box {
            //评论
            .storm-user-comment {
              margin-top: 20px;
              height: calc(100vh - 376px);
              overflow-y: auto;
              //评论
              .storm-user-comment-message {
                .storm-user-comment-box {
                  .storm-user-header {
                    cursor: pointer;
                  }
                }
              }
            }
            .storm-user-form {
              position: absolute;
              bottom: 0;
              width: 100%;
              .ant-form {
                display: flex;
                justify-content: space-between;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 0;
                }
                input {
                  width: 100%;
                  height: 40px;
                  border-radius: 4px;
                  border: 1px solid #c8c8c8;
                }
                input:focus {
                  border: 1px solid #fd492b;
                }
                .storm-user-form-button {
                  width: 67px;
                  button {
                    width: 67px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .complie-box-model {
    margin-top: -80px;
    transition: 0.5s;
  }
  .comlie-content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

@media screen and(min-width:414px) and(max-width:736px) and (-webkit-device-pixel-ratio: 3) {
  .complie-box {
    .comlie-center-content {
      #components-layout-demo-side {
        .comlie-right-menu-box {
          height: calc(100vh - 280px);
          position: absolute;
          z-index: 500;
          left: 0;
          right: 0;
          bottom: 0px;
          max-width: 100%;
          .storm-comment-box {
            //评论
            .storm-user-comment {
              margin-top: 20px;
              height: calc(100vh - 376px);
              overflow-y: auto;
              //评论
              .storm-user-comment-message {
                .storm-user-comment-box {
                  .storm-user-header {
                    cursor: pointer;
                  }
                }
              }
            }
            .storm-user-comment-nodata {
              height: 424px;
            }
            .storm-user-form {
              position: absolute;
              bottom: 0;
              width: 100%;
              .ant-form {
                display: flex;
                justify-content: space-between;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 0;
                }
                input {
                  width: 100%;
                  height: 40px;
                  border-radius: 4px;
                  border: 1px solid #c8c8c8;
                }
                input:focus {
                  border: 1px solid #fd492b;
                }
                .storm-user-form-button {
                  width: 67px;
                  button {
                    width: 67px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .complie-box-model {
    margin-top: -80px;
    transition: 0.5s;
  }
  .comlie-content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .complie-box {
    .comlie-center-content {
      #components-layout-demo-side {
        .comlie-left-content {
          .crisps-detial-header {
            padding: 0px 10px 0px 10px;
            min-width: 0px;
            min-height: 26px;
            position: absolute;
            left: 50%;
            top: 10px;
            transform: translate(-50%, 20%);
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: start;
            width: 100%;
            .crisps-user-message {
              display: flex;
              align-items: center;
              margin-right: 10px;
              min-width: 85px;
              cursor: pointer;
              .user-header {
                border: 1px solid #ffffff;
                border-radius: 50%;
                img {
                  width: 34px;
                  height: 34px;
                  border-radius: 50%;
                }
              }
              .userIsMember {
                display: flex;
                justify-content: center;
                background-image: url(../../assets/img/user/crown.png);
                background-repeat: no-repeat;
                width: 26px;
                height: 36px;
                margin: 0 auto;
                position: relative;
                top: -4px;
                right: 5px;
                background-size: 100%;
                img {
                  position: absolute;
                  top: 8px;
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                }
              }
              .user-name {
                margin-left: 10px;
                font-size: 16px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 22px;
                color: #666666;
                opacity: 1;
                max-width: 69px;
                overflow: hidden;
                height: 22px;
              }
            }
            .crisps-detials-handle {
              display: flex;
              align-items: center;
              position: absolute;
              right: 10px;
              .crisps-like-box {
                min-width: 44px;
                margin-right: 10px;
                .crisps-like {
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  span:last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }
        .comlie-right-menu-box {
          position: absolute;
          z-index: 500;
          left: 0;
          right: 0;
          bottom: 0px;
          height: 500px;
          max-width: 100%;
          .storm-comment-box {
            margin: 0;
            position: relative;
            height: 100%;
            .storm-comment-text {
              display: flex;
              justify-content: center;
              font-size: 18px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 25px;
              color: #333333;
              opacity: 1;
            }
            //评论
            .storm-user-comment {
              margin-top: 20px;
              height: 405px;
              overflow-y: auto;
              //评论
              .storm-user-comment-message {
                .storm-user-comment-box {
                  display: flex;
                  min-height: 109px;
                  border-bottom: 1px solid #ececec;
                  align-items: center;
                  flex-wrap: nowrap;
                  .storm-user-header {
                    width: 42px;
                    height: 80px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    .storm-user-img {
                      border: 1px solid #ffffff;
                      width: 42px;
                      height: 42px;
                      border-radius: 50%;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                    .storm-user-img-ismember {
                      border: 1px solid #ffffff;
                      border-radius: 50%;
                      background-image: url(../../../static/img/userData/user_message_vip_bg.png);
                      background-size: 100%;
                      width: 42px;
                      height: 52px;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                  }
                  .storm-comment-content {
                    min-width: 200px;
                    margin-left: 10px;
                    max-width: 984px;
                    width: 100%;
                    .comment-content-header {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .comment-user-name {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: inherit;
                        line-height: 17px;
                        color: #333333;
                        opacity: 1;
                        max-height: 20px;
                        span {
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: 60px;
                          overflow: hidden;
                        }
                        .comment-author-logo {
                          margin-left: 26px;
                          padding: 3px 6px;
                          border-radius: 2px;
                          background: #ec705a;
                          color: #ffffff;
                        }
                      }
                      .comment-cotnent-detials {
                        button {
                          border: none;
                          padding: 0 10px;
                        }
                      }
                    }
                    .comment-content-box {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .comment-content-text {
                        width: 266px;
                        font-size: 14px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #333333;
                        opacity: 1;
                      }
                    }
                    .comment-content-reply {
                      display: flex;
                      justify-content: space-between;
                      margin-top: 6px;
                      .comment-time {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 17px;
                        color: #999999;
                        opacity: 1;
                      }
                      .comment-reply {
                        cursor: pointer;
                        margin: 0;
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        /*font-weight: bold;*/
                        line-height: 17px;
                        color: #666666;
                        opacity: 1;
                      }
                    }
                  }
                }
              }
              //回复
              .storm-comment-reply {
                border-bottom: 1px solid #ececec;
                padding: 0 0 20px 0;
                .storm-user-comment-message-reply {
                  display: flex;
                  align-items: center;
                  margin-left: 50px;
                  .storm-user-header {
                    width: 42px;
                    height: 80px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    .storm-user-img {
                      border: 1px solid #ffffff;
                      width: 42px;
                      height: 42px;
                      border-radius: 50%;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                    .storm-user-img-ismember {
                      border: 1px solid #ffffff;
                      border-radius: 50%;
                      background-image: url(../../../static/img/userData/user_message_vip_bg.png);
                      background-size: 100%;
                      width: 42px;
                      height: 52px;
                      img {
                        width: 42px;
                        height: 42px;
                        border-radius: 50%;
                        margin-top: 12px;
                      }
                    }
                  }
                  .storm-comment-content {
                    min-width: 200px;
                    margin-left: 10px;
                    max-width: 984px;
                    width: 100%;
                    .comment-content-header {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .comment-user-name {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: inherit;
                        line-height: 17px;
                        color: #333333;
                        opacity: 1;
                        max-height: 20px;
                        span {
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: 60px;
                        }
                        .comment-author-logo {
                          margin-left: 26px;
                          padding: 3px 6px;
                          border-radius: 2px;
                          background: #ec705a;
                          color: #ffffff;
                        }
                      }
                      .comment-cotnent-detials {
                        button {
                          border: none;
                          padding: 0 10px;
                        }
                      }
                    }
                    .comment-content-box {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      min-width: 200px;
                      max-width: 322px;
                      width: 100%;
                      .comment-content-text {
                        width: 266px;
                        font-size: 14px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 20px;
                        color: #333333;
                        opacity: 1;
                      }
                    }
                    .comment-content-reply {
                      display: flex;
                      justify-content: space-between;
                      margin-top: 6px;
                      .comment-time {
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        font-weight: 400;
                        line-height: 17px;
                        color: #999999;
                        opacity: 1;
                      }
                      .comment-reply {
                        cursor: pointer;
                        margin: 0;
                        font-size: 12px;
                        font-family: Noto Sans SC;
                        /*font-weight: bold;*/
                        line-height: 17px;
                        color: #666666;
                        opacity: 1;
                      }
                    }
                  }
                  //回复
                  // .comment-reply-from{
                  //   width: 100%;
                  //   height: 50px;
                  //   border: 1px solid red;
                  //   margin: 0 13px;
                  // }
                }
                .comment-reply-more {
                  display: flex;
                  margin-left: 50px;
                  font-size: 12px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 17px;
                  color: #666666;
                  opacity: 1;
                  cursor: pointer;
                  align-items: center;
                  .more-comment-logo {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 6px solid #666666;
                    border-radius: 3px;
                    margin-left: 6px;
                  }
                }
              }
            }
            .storm-user-comment-nodata {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 439px;
              .nodata-box {
                img {
                  width: 202px;
                  height: 214px;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 14px;
                  font-family: Noto Sans SC;
                  font-weight: 400;
                  line-height: 20px;
                  color: #333333;
                  opacity: 1;
                  text-align: center;
                }
              }
            }
            .storm-user-comment::-webkit-scrollbar {
              width: 10px;
              height: 10px;
              /**/
            }
            .storm-user-comment::-webkit-scrollbar-track {
              background: rgb(239, 239, 239);
              border-radius: 2px;
            }
            .storm-user-comment::-webkit-scrollbar-thumb {
              background: #bfbfbf;
              border-radius: 10px;
            }
            .storm-user-comment::-webkit-scrollbar-thumb:hover {
              background: #b4b4b4;
              cursor: pointer;
            }
            .storm-user-form {
              position: absolute;
              bottom: 0;
              width: 100%;
              .ant-form {
                display: flex;
                justify-content: space-between;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 0;
                }
                input {
                  width: 100%;
                  height: 40px;
                  border-radius: 4px;
                  border: 1px solid #c8c8c8;
                }
                input:focus {
                  border: 1px solid #fd492b;
                }
                .storm-user-form-button {
                  width: 67px;
                  button {
                    width: 67px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .complie-box-model {
    margin-top: -80px;
    transition: 0.5s;
  }
  .comlie-content {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

@media screen and(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .complie-box {
    .comlie-content {
      min-width: 1024px;
    }
  }
  .storm-user-comment {
    height: 556px !important;
  }
  .complie-box {
    .comlie-center-content {
      #components-layout-demo-side {
        .comlie-left-content {
          .crisps-detial-header {
            padding: 0px 10px 0px 10px;
            min-width: 0px;
            min-height: 26px;
            position: absolute;
            left: 50%;
            top: 10px;
            transform: translate(-50%, 20%);
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: start;
            width: 100%;
          }
          .crisps-detials-handle {
            display: flex;
            align-items: center;
            position: absolute;
            right: 10px;
            .crisps-like-box {
              min-width: 44px;
              margin-right: 10px;
              .crisps-like {
                cursor: pointer;
                display: flex;
                align-items: center;
                span:last-child {
                  margin-left: 10px;
                }
              }
            }
          }
        }
        .comlie-right-menu-box {
          .storm-comment-box {
            margin: 0;
            .storm-user-form {
              position: absolute;
              bottom: 0;
              width: 100%;
              .ant-form {
                display: flex;
                justify-content: space-between;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 0;
                }
                input {
                  width: 100%;
                  height: 40px;
                  border-radius: 4px;
                  border: 1px solid #c8c8c8;
                }
                input:focus {
                  border: 1px solid #fd492b;
                }
                .storm-user-form-button {
                  width: 67px;
                  button {
                    width: 67px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .complie-box-model {
    margin-top: -80px;
    transition: 0.5s;
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) {
  .complie-box {
    .comlie-center-content {
      #components-layout-demo-side {
        .comlie-right-menu-box {
          .storm-comment-box {
            .storm-user-form {
              position: absolute;
              bottom: 0;
              width: 100%;
              .ant-form {
                display: flex;
                justify-content: space-between;
                .ant-form-item {
                  width: 100%;
                  margin-bottom: 0;
                }
                input {
                  width: 100%;
                  height: 40px;
                  border-radius: 4px;
                  border: 1px solid #c8c8c8;
                }
                input:focus {
                  border: 1px solid #fd492b;
                }
                .storm-user-form-button {
                  width: 67px;
                  button {
                    width: 67px;
                    height: 40px;
                    opacity: 1;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>


<style scoped>
/**背景 */
.bg-color {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.template {
  /* height: 100vh; */
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}
.comlie-content-menu {
  width: 100%;
  height: 100%;
  height: 68px;
  position: relative;
}
.comlie-content {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  z-index: 100;
  width: 100%;
  position: absolute;
  min-width: 1200px;
}

.template_share {
  z-index: 10;
  position: absolute;
  right: 254px;
  height: 46px;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 100px;
}

.template_share .share {
  display: flex;
  align-items: center;
}

.template_share img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.complie-box-model {
  transition: 0.5s;
}
.cropperImgAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.cropperImgAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLinkAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLinkAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
</style>